var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Checkbox, useAlertDialog } from '@innogrid-ui/core';
import { useQuery } from '@tanstack/react-query';
import { isNull } from 'lodash';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDataSet, getInitProcessData, } from '@/pages/console/myData/applicationDataManagement/dataProcess/_components/promise';
import { useAuthStore, useDataProcessStore } from '@/stores';
import { validateError } from '@/utils';
import styles from '../ApplicationDataManagementPage.module.scss';
import { COL_DEF } from '../constant';
import axiosUtil from "@/utils/axios";
const ProcessButton = ({ item }) => {
    var _a, _b, _c;
    const { open } = useAlertDialog();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { userSeq } = useAuthStore((state) => state.userInfo);
    const setId = useDataProcessStore((state) => state.setId);
    const setInitDataset = useDataProcessStore((state) => state.setInitDataset);
    const { dataId, title, applyDate, datatype, accessUrl, downloadUrl, status } = item;
    const existingProcessInfo = (_a = useDataProcessStore((state) => state.processInfo)) === null || _a === void 0 ? void 0 : _a[`${userSeq}_${dataId}`];
    const processCount = (_b = existingProcessInfo === null || existingProcessInfo === void 0 ? void 0 : existingProcessInfo.totalProcessCount) !== null && _b !== void 0 ? _b : 0;
    const { refetch: getInitData } = useQuery({
        queryKey: ['getInitProcessData', dataId],
        queryFn: ({ signal }) => getInitProcessData({
            data: {
                dataset_id: dataId,
                dataset_nm: title,
                data_type: datatype === 'dataset' ? 'FILE' : datatype.toUpperCase(),
                file_path: downloadUrl,
                api_url: accessUrl,
            },
            signal,
        }),
        enabled: false,
    });
    const { refetch: getDatasetList } = useQuery({
        queryKey: ['getDataSet', (_c = existingProcessInfo === null || existingProcessInfo === void 0 ? void 0 : existingProcessInfo.processResult) === null || _c === void 0 ? void 0 : _c.datasetId],
        queryFn: () => { var _a; return getDataSet({ dataset_id: ((_a = existingProcessInfo === null || existingProcessInfo === void 0 ? void 0 : existingProcessInfo.processResult) === null || _a === void 0 ? void 0 : _a.datasetId) || '' }); },
        enabled: false,
    });
    const onClickProcess = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!dataId)
            return;
        setIsLoading(true);
        try {
            const refetchData = processCount === 0 ? yield getInitData() : yield getDatasetList();
            const { data, isSuccess } = refetchData;
            if (!isSuccess ||
                validateError(data, open) ||
                !(data === null || data === void 0 ? void 0 : data.result) ||
                (data.result.datasetId === null &&
                    validateError({ code: '500', message: '데이터 가공을 처리할 수 없습니다.' }, open))) {
                return;
            }
            const newId = `${userSeq}_${dataId}`;
            setId(newId);
            setInitDataset(newId, data.result);
            navigate(`/my-data/application-data-management/data-process`, {
                state: { title, createdAt: applyDate, type: datatype },
            });
        }
        finally {
            setIsLoading(false);
        }
    });
    return (_jsx(Button, Object.assign({ color: "common", mode: "default", onClick: isLoading ? () => { } : onClickProcess, size: "small", variant: "outlined", disabled: !isNull(status) }, { children: isLoading ? (_jsx("div", Object.assign({ className: styles.loaderWrapper }, { children: _jsx("div", { className: styles.circle }) }))) : ('데이터 가공') })));
};
const InfoButton = ({ type, accessUrl, downloadUrl, title }) => {
    const { open } = useAlertDialog();
    // const onClick = () => {
    // 	const url = type === 'dataset' ? downloadUrl : accessUrl;
    // 	const errorMessage = type === 'dataset' ? '다운로드 할 파일이 없습니다.' : '접근할 URL이 없습니다.';
    //
    // 	if (!url) return validateError({ code: '500', message: errorMessage }, open);
    //
    // 	type === 'dataset' ? (window.location.href = url) : window.open(url, '_blank');
    // };
    const onClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const url = type === 'dataset' ? downloadUrl : accessUrl;
        const errorMessage = type === 'dataset' ? '다운로드 할 파일이 없습니다.' : '접근할 URL이 없습니다.';
        if (!url)
            return validateError({ code: '500', message: errorMessage }, open);
        if (type === 'dataset') {
            const urlParams = new URLSearchParams(new URL(url).search);
            const distributionId = urlParams.get('distributionId');
            const fileName = urlParams.get('fileName');
            const sampleType = urlParams.get('sampleType');
            const response = yield axiosUtil.get(`/api/v1/public-data/data/combine/download`, {
                params: {
                    "distributionId": distributionId,
                    "fileName": fileName,
                    "sampleType": sampleType
                },
                responseType: 'blob',
            });
            // 서버에서 받은 바이너리 데이터를 Blob 형태로 변환
            const blob = new Blob([response], { type: 'text/csv' }); // CSV 파일로 MIME 타입 설정
            // 브라우저에서 파일 다운로드를 트리거
            const downloadUrlDataset = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrlDataset;
            // link.download = fileName ? fileName : 'downloadFile.csv';
            link.download = title;
            document.body.appendChild(link);
            link.click();
            // 다운로드 후 링크 제거
            document.body.removeChild(link);
        }
        else {
            window.open(url, '_blank');
        }
    });
    return (_jsx(Button, Object.assign({ color: "common", mode: "default", onClick: onClick, size: "small", variant: "outlined" }, { children: "\uBC14\uB85C\uAC00\uAE30" })));
};
const SelectionCheckbox = ({ id, isSelected, onSelect, }) => {
    return (_jsx("div", Object.assign({ className: styles.chkBox }, { children: _jsx(Checkbox, { id: `checkbox-${id}`, checked: isSelected, onCheckedChange: (checked) => onSelect(id, checked), layout: "horizontal" }) })));
};
const createColumnRenderers = (handlers, selectedItems) => {
    return {
        checkbox: (item) => (_jsx(SelectionCheckbox, { id: item.routeInfoSeq, isSelected: selectedItems.includes(item.routeInfoSeq), onSelect: handlers.onItemSelect })),
        title: (item) => _jsx("div", Object.assign({ className: styles.txLeft }, { children: item.title })),
        datatype: (item) => item.datatype.toUpperCase() || '-',
        applyDate: (item) => moment(item.applyDate).format('YYYY-MM-DD'),
        dataInfo: (item) => (_jsx(InfoButton, { type: item.datatype, accessUrl: item.accessUrl, downloadUrl: item.downloadUrl, title: item.title })),
        dataUsage: (item) => _jsx(ProcessButton, { item: item }),
    };
};
export const RenderApplicationDataColumns = (handlers, selectedItems) => {
    const renderers = createColumnRenderers(handlers, selectedItems);
    return COL_DEF.map((column) => (Object.assign(Object.assign({}, column), { render: renderers[column.key] || column.render })));
};
